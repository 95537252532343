.ChargingHistoryPage {
    margin-bottom: 50px;
}

.ChargingHistoryItemWrapper {
    border-bottom: 1px solid #EAEAEA;
    padding-bottom: 12px;
    margin-top: 12px;
}

.ChargingHistoryItemWrapper .detail .iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: var(--tg-soft-green);
    margin-right: 12px;
}

.ChargingHistoryItemWrapper .detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ChargingHistoryItemWrapper .detail .center-side p {
    max-width: 220px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.ChargingHistoryItemWrapper .detail .right-side {
    margin-left: auto;
}

.ChargingHistoryItemWrapper .detail .transaction-wrapper {
    display: flex;
    align-items: center;
}

.ChargingHistoryItemWrapper .detail .transaction-dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: var(--tg-secondary-text);
    margin: 0 8px 0 6px;
}

