ion-toast.custom-toast {
    --background: #FFFFFF;
    --box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.2);
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
}

ion-toast.custom-toast::part(icon) {
    font-size: 22px;
}

ion-toast.custom-toast::part(header) {
    font-size: 14px;
    font-weight: 600;
    color: #191E26;
}

ion-toast.custom-toast::part(message) {
    color: #166DF7;
    font-size: 14px;
    font-weight: 500;
    opacity: .8;
}

ion-toast.custom-toast::part(button) {
    color: #191E26;
    font-size: 12px;
    font-weight: normal;
    opacity: .7;
}