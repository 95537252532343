.corporates {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 1rem;
}

.companies-list-item-wrapper {
    border: 1px solid var(--tg-inactive);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    overflow: hidden;
}

.companies-list-item {
    display: flex;
    justify-content: space-between;
}

.companies-list-item p {
    flex: 1;
}

.companies-list {
    flex: 1 1 100%;
}

.companies-list__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
