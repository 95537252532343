.SearchModalHeader {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 13px;
    gap: 10px;
}
.NearbyStationsSwiper .swiper-wrapper {
    padding: 8px 16px 0;
}
.NearbyStationsSwiper .swiper-slide{
    height: 75px;
}
.NearbyStationsSliderItem{
    height: 65px;
    width: 96%;
    background-color: var(--tg-white);
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 12px;
    padding: 8px;
    gap: 11px;
    box-shadow: 0 0.5px 2px #0000001A;
}
.NearbyStationsSliderItem.no-item{
    height: fit-content;
    width: 92%;
    background-color: var(--tg-white);
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    padding: 0px;
    gap: 11px;
    margin: 0 auto;
    box-shadow: 0px 0.5px 4px #0000001A;
}
.NearbyStationsSliderItemInformation{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.NearbyStationsSliderItemInformation_FirstLine{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    gap: 10px;
}
.NearbyStationsSliderItemInformation_ClosestStationDistanceWrapper{
    height: 20px;
    padding: 0px 6px;
    background-color: var(--tg-primary-text);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;    
}
.NearbyStationsSliderItemInformation .StationName {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.RecentSearches {
    margin-top: 16px;
}
.RecentSearchesTitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.SearchNotFound {
    width: 100%;
    text-align: center;
}
.SearchResults{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.SearchResultsList{
    display: flex;
    flex-direction: row;
    width: calc(100% - 16px);
    margin: 0 auto;
    height: 100%;
}
.SearchResultsList .SearchResultsText{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.SearchResultsItem{
    display: flex;
    flex-direction: row;
    padding: 13px 0;
    width: calc(100% - 16px);
    margin: 0 auto;
    gap: 10px;
}
.SearchResultsItem:not(:last-child){
    border-bottom: 1px solid #ebebeb;
}