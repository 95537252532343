ion-modal#first_time {
    --width: fit-content;
    --min-width: 250px;
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    --max-width: calc(100% - 20px);
}

ion-modal#first_time h1 {
    margin: 20px 20px 10px 20px;
}

ion-modal#first_time ion-icon {
    margin-right: 6px;

    width: 48px;
    height: 48px;

    padding: 4px 0;

    color: #aaaaaa;
}

ion-modal#first_time .wrapper {
    margin-bottom: 10px;
}


ion-modal#first_time .header {
    margin: 15px;
    text-align: end;
}

ion-modal#first_time .content {
    text-align: center;
    padding:0 20px 20px;
}
ion-modal#first_time p {
    font-size: 14px;
}

ion-modal#first_time button {
    background: var(--tg-dark);
    border-radius: 64px;
    color: #fff;
    width: 100%;
    font-weight: 500;
    height: 55px;
    font-size: 16px;
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

ion-modal#first_time button:active {
    background: var(--tg-black-active);
}

