.GlobalModal * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.GlobalModal img {
    width: 80%;
}

.GlobalModal h2 {
    font-size: 18px;
    font-weight: 600;
    margin: 26px 0 10px;
}

.GlobalModal .GlobalModalContent {
    padding: 0 22px;
    margin-bottom: 35px;
}

.GlobalModal .GlobalModalContent button {
    background: var(--tg-dark);
    border-radius: 64px;
    color: #fff;
    width: 100%;
    font-weight: 500;
    height: 55px;
    font-size: 16px;
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.GlobalModal .GlobalModalContent button:active {
    background: var(--tg-black-active);
}

