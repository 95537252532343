/* Core CSS required for Ionic components to work properly */
@import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/react/css/normalize.css';
@import '@ionic/react/css/structure.css';
@import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/react/css/padding.css';
@import '@ionic/react/css/float-elements.css';
@import '@ionic/react/css/text-alignment.css';
@import '@ionic/react/css/text-transformation.css';
@import '@ionic/react/css/flex-utils.css';
@import '@ionic/react/css/display.css';

/* CSS Files */
@import "react-credit-cards-2/dist/es/styles-compiled.css";

@import "./TGElements.css";
@import "./map.css";
@import "./menu.css";
@import "./register.css";
@import "./map-buttons.css";
@import "./alert.css";
@import "./station-detail.css";
@import "./vehicle.css";
@import "./swiper-style.css";
@import "./onboarding.css";
@import "./animation.css";
@import "./bottom-toolbar.css";
@import "./ios-safe-area.css";
@import "./search-modal.css";
@import "./scanner.css";
@import "./confirm-modals.css";
@import "./invoice.css";
@import "./active-session.css";
@import "./notifications.css";
@import "./receipt.css";
@import "./payment-methods.css";
@import "./default-page.css";
@import "./no-entries.css";
@import "./action-success-modal.css";
@import "./start-session.css";
@import "./variables.css";
@import "./tickets.css";
@import "./wallet.css";
@import "./charging-history.css";
@import "./membership.css";
@import "./select-payment-method.css";
@import "./global-modal.css";



*{
    font-family: var(--tg-font-family)
}

body {
    width: 100%;
}

/* Hide Google Map copyright */
a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}
.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

ion-button {
  min-height: fit-content;
}