
.TicketList {
    display: flex;
    flex-direction: column;
    height: 100%;

}
.TicketItem {
    border: 1px solid var(--tg-gray);
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.TicketItem.bg-active {
    background: var(--tg-gray-6);
}

.TicketItem:not(:last-child) {
    margin-bottom: 1rem;
}

.TicketInner{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding-right: 8px;
}

.TicketShortInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.TicketSecondLine {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.TicketStatus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
}
.TicketStatus span {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    display: inline-block;
}

.TicketDetailTop {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.TicketThread {
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
}

.TicketThread.supervisor {
    background: 1px solid var(--tg-gray);
}

.TicketThread.supervisor p p {
    margin: 0;
}

.TicketThread.customer {
    background: var(--tg-gray);
}

.TicketThread .downloadFile {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.TicketThread .imageAttachment {
    width: 40%;
    margin-top: 15px;
}

.ThreadTop {
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
}

.TicketForm {
    height: calc(100% - 10rem);
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.fileAttachmentWrapper input {
    display: none;
}

.fileAttachmentWrapper #text-icon {
    font-size: 20px;
    height: 22px;
    color: var(--tg-secondary-text);
}

.fileAttachmentWrapper #icon {
    font-size: 25px;
    color: var(--tg-park);
    border-left: 1px solid var(--tg-inactive-border);
    padding-left: 15px;
    margin: 10px 0;
}
.fileAttachmentWrapper .fileDescWrapper {
    display: flex;
    align-content: center;
    gap: 5px;
    width: 72%;
}

.fileAttachmentWrapper * {
    flex-shrink: 0;
}

.fileAttachmentWrapper {
    display: flex;
    gap: 8px;
    width: 100%;
    align-items: center;
    margin-bottom: 1rem;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--tg-inactive-border);
    justify-content: space-between;
    margin-top: 15px;
    padding: 0 20px;
}

.TicketForm .submitBtn {
    position: fixed;
    z-index: 2;
    bottom: 2rem;
    width: calc(100% - 2rem);
}

.TicketForm > * {
    flex-shrink: 0;
}