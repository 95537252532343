.TGLogoOnMap.active{
    opacity: 25%;
}
.TGLogoOnMap{
    position: absolute;
    bottom: 95px;
    right: -40px;
    z-index: 999;
    transform: scale(0.5);
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.MapLoadingBackground {
    width: 100vw;
    height: 100vh;
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
    transform: scale(1.5);
}

.MapLoadingBackground.active{
    opacity: 1;
    z-index: 1;
}

.ScanButton::part(native) {
    --background: var(--tg-primary);
    --box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.1);
    --padding-top: 0;
    --padding-start: 0;
    --padding-end: 0;
    --padding-bottom: 0;

    height: 53px;
    width: 53px;
    margin: 0;
    border-radius: 53px;
}

.ScanButtonWrapper {
    width: 76px;
    height: 75px;
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="76.102" height="75.15" viewBox="0 0 76.102 75.15"><path id="Path_289553" data-name="Path 289553" d="M62.5,63.5c-4.8-.818-8.85,1.258-13.1,3.339A33.3,33.3,0,1,1,33.3,4.373a33.546,33.546,0,0,1,5.43.441C47.838,5.46,53.99,13.1,62.5,11.653,71.027,10.2,76.1,0,76.1,0V75.15S71.027,64.951,62.5,63.5Z" fill="%23bebfc1" opacity="0.5"/></svg>');
    display: flex;
    align-items: center;
    padding-left: 6px;
}