.BottomToolbarTemp{
    width: 100%;
    display: flex;
    justify-content: center;
}

.BottomToolbarSwiper.active{
    transform: translateY(0);
}
.BottomToolbarSwiper{
    transform: translateY(100px);
    transition: all 0.3s ease-in-out;
}
.BottomToolbarSwiper .swiper-wrapper {
    padding-left: 16px;
}
.BottomToolbarSwiper .swiper-slide{
    height: 85px;
}
.BottomToolbarItem{
    height: 65px;
    width: 92%;
    background-color: var(--tg-white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    padding: 0 16px 0 8px;
    gap: 11px;
    box-shadow: 1px 3px 13px 0px #0000000d;
    overflow-y: hidden;
}
.BottomToolbarItemRight{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.BottomToolbarItemInformation{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.BottomToolbarItemInformation_FirstLine{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    gap: 10px;
}
.BottomToolbarItemInformation_ClosestStationDistanceWrapper{
    height: 20px;
    padding: 0px 6px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;    
}