.ConfirmRemoveUserModal .Content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px
}

.ConfirmMailModal .confirm-mail-text b {
    color: #191E26;
    font-weight: 600;
}