.Receipt {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

ion-backdrop {
    opacity: 0.50;
    background: #191E26;
}

.ReceiptContent {
    position: absolute;
    z-index: 3;
    top: 5%;
    background: var(--tg-white);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 339px;
    height: 90%;
    border-radius: 20px;
    animation: receiptContentAnimation 2s ease-in-out;
}

.SuccessAnim {
    position: absolute;
    z-index: 4;
    top: 0;
    background: var(--tg-white);
    border-radius: 60px;
    transform: translateY(15%);
    animation: sucessIconAnimation 1s ease-in-out;
}

.ReceiptContent>div {
    height: 100%;
    width: 100%;
    padding: 30px 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    animation: initAnim 2s ease-in-out;
}

.ReceiptLine {
    height: 15%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.ReceiptLine>hr {
    border-top: 1px dashed var(--tg-inactive);
    width: 100%;
}

.ReceiptLine:before, .ReceiptLine:after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    background: #8c8e92;
    border-radius: 40%;
}

.ReceiptLine:before {
    left: 0;
    transform: translateX(-120%) rotate(45deg);
}

.ReceiptLine:after {
    right: 0;
    transform: translateX(120%) rotate(45deg);
}

.ReceiptCard{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 77px;
    background-color: var(--tg-receipt-card);
    border-radius: 12px;
    margin-top: 30px;
    gap: 20px;
    padding: 16px;
}


@media only screen and (min-height: 750px) {
    .ReceiptContent {
        position: absolute;
        z-index: 3;
        top: 13%;
        background: var(--tg-white);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 339px;
        height: 70%;
        border-radius: 20px;
        animation: receiptContentAnimation 2s ease-in-out;
    }

    .SuccessAnim {
        position: absolute;
        z-index: 4;
        top: 8%;
        background: var(--tg-white);
        border-radius: 60px;
        transform: translateY(30%);
        animation: sucessIconAnimation 1s ease-in-out;
    }

    .ReceiptLine {
        height: 11%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}
