.StartSessionContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    flex: 1 1 100%;
    height: 100%;
    background-color: var(--tg-white);
}

.StartSessionContent .StartSessionHeader {
    margin-top: 25px;
    margin-bottom: 25px;
}

.StartSessionContent .StartSessionForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 3;
    padding: 0 16px;
    justify-content: space-between;
}

.ScanContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 9px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.10);
    width: 100%;
    margin: 0 auto;
}

.FrameContainer{
    border-radius: 10px;
    padding: 54px 45px;
}

.FrameContainer .Frame {
    position: relative;
    width: 224px;
    height: 209px;
    padding: 16px;
}
.FrameContainer .FrameIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.Frame .LeftBottom{
    position: absolute;
    bottom: 7%;
    left: 7%;
    border-left: 2px solid var(--tg-primary);
    border-bottom: 2px solid var(--tg-primary);
    border-radius: 3px;
    width: 25px;
    height: 25px;
}
.Frame .RightBottom{
    position: absolute;
    bottom: 7%;
    right: 7%;
    border-right: 2px solid var(--tg-primary);
    border-bottom: 2px solid var(--tg-primary);
    border-radius: 3px;
    width: 25px;
    height: 25px;
}
.Frame .LeftTop{
    position: absolute;
    top: 7%;
    left: 7%;
    border-left: 2px solid var(--tg-primary);
    border-top: 2px solid var(--tg-primary);
    border-radius: 3px;
    width: 25px;
    height: 25px;
}
.Frame .RightTop{
    position: absolute;
    top: 7%;
    right: 7%;
    border-right: 2px solid var(--tg-primary);
    border-top: 2px solid var(--tg-primary);
    border-radius: 3px;
    width: 25px;
    height: 25px;
}

.ScannerDivider{
    width: 100%;
    height: 15px;
    position: relative;
}

.ScannerDivider hr  {
    width: 100%;
    display: inline-block;
    border-bottom: dashed 1px var(--tg-gray);
}
.ScannerDivider .DividerLeft {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: rgba(241, 241, 241, 1);
    position: absolute;
    display: inline-block;
    left: -7px;
}
.ScannerDivider .DividerRight {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: rgba(241, 241, 241, 1);
    position: absolute;
    display: inline-block;
    right: -7px;
}
.StartSession .ConfirmButtonWrapper{
    width: 100%;
    position: relative;
    transition: bottom ease-in .3s;
    margin: 20px auto;
}

.StartSession .ConfirmButtonWrapper.keyboard-true{
    position: relative;
    transition: bottom ease-in .3s;
    bottom: 15%;
}

.CodeInputWrapper {
    width: 100%;
    transition: all ease-in .3s;
}

.CodeInputWrapper.keyboardActive{
    z-index: 999;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    padding: 20px 10px 0;
    width: calc(100% - 30px);
    border-radius: 15px;
}

.StartSession .ConfirmButtonWrapper.keyboardActive{
    width: calc(100% - 32px);
    margin: 0 auto;
    position: absolute;
    bottom: 10%;
    z-index: 998;
}
.TooltipText{
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: 24px;
}
.TooltipText svg{
    transform: translateX(-15px);
}

@media only screen and (max-height: 750px) {
    .StartSessionContent {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .FrameContainer .Frame {
        position: relative;
        width: 180px;
        height: 180px;
        padding: 16px;
    }
    .StartSession .ConfirmButtonWrapper.keyboardActive{
        width: calc(100% - 32px);
        margin: 0 auto;
        position: absolute;
        bottom: 5%;
        z-index: 998;
    }
}
