.scanner-ui {
    opacity: 0;
    width: 100%;
    height: 100%;
    transition: all linear .2s;
}

.ScannerPage {
    --background: var(--tg-black);
    --ion-background-color: var(--tg-black);
    transition: all linear .2s;
}

body.scanner-active .ScannerPage {
    --background: transparent;
    --ion-background-color: transparent;
}

body.scanner-active .scanner-ui {
    opacity: 1;
}

.scanner-ui > .background {
    width: 100%;
    height: 100%;
    background-color: var(--tg-black);
    clip-path: polygon(0% 0%, 0% 100%, 15% 100%, 15% 25%, 85% 25%, 85% 60%, 15% 60%, 15% 100%, 100% 100%, 100% 0%);
    position: absolute;
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%, rgba(255,255,255,0) 60%, rgba(0,0,0,1) 100%);
    opacity: 1;
}

.scanner-ui > .background-flash-active {
    width: 100%;
    height: 100%;
    background-color: var(--tg-black);
    clip-path: polygon(0% 0%, 0% 100%, 15% 100%, 15% 25%, 85% 25%, 85% 60%, 15% 60%, 15% 100%, 100% 100%, 100% 0%);
    position: absolute;
    background: var(--tg-black);
    opacity: 0.7;
}

.scanner-ui .scanText {
    position: relative;
    top: 60%;
}

.scanner-ui .TGBackButton {
    position: relative;
    top: 6%;
    left: 3%;
}

.scanner-ui .outline {
    position: absolute;
    top: 24.5%;
    left: 15%;
    display: inline-block;
    width: 70%;
    height: 36%;
    border: 3px solid var(--tg-primary);
    border-radius: 10px;
    padding: 16px;
}

.scanner-ui .frame-left-bottom{
    position: absolute;
    bottom: 7%;
    left: 7%;
    border-left: 2px solid var(--tg-white);
    border-bottom: 2px solid var(--tg-white);
    border-radius: 3px;
    width: 25px;
    height: 25px;
}
.scanner-ui .frame-right-bottom{
    position: absolute;
    bottom: 7%;
    right: 7%;
    border-right: 2px solid var(--tg-white);
    border-bottom: 2px solid var(--tg-white);
    border-radius: 3px;
    width: 25px;
    height: 25px;
}
.scanner-ui .frame-left-top{
    position: absolute;
    top: 7%;
    left: 7%;
    border-left: 2px solid var(--tg-white);
    border-top: 2px solid var(--tg-white);
    border-radius: 3px;
    width: 25px;
    height: 25px;
}
.scanner-ui .frame-right-top{
    position: absolute;
    top: 7%;
    right: 7%;
    border-right: 2px solid var(--tg-white);
    border-top: 2px solid var(--tg-white);
    border-radius: 3px;
    width: 25px;
    height: 25px;
}

.scanner-ui .scan-animated{
    position: absolute;
    left: 7%;
    top: 0;
    background-color: var(--tg-white);
    width: 85%;
    height: 2px;
    animation: scan-animation 2s infinite;
}
.scanner-ui .FlashButton{
    position: relative;
    top: 70%;
}

.scanner-ui .EnterCodeButton{
    position: relative;
    top: 65%;
    min-height: 55px;
}

@keyframes scan-animation {
    0% {
        transform: translateY(18px);
    }
    50%{
        transform: translateY(215px);
    }
    100% {
        transform: translateY(18px);
    }
}