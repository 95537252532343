.MenuContent{
    height: calc(100% - 63px);
    display: flex;
    flex-direction: column;
}
.MenuHighlights{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.MenuHighlight {
    width: 100%;
    height: 90px;
    border-radius: 20px;
    padding: 15px;
    position: relative;
}

.MenuHighlight.Payments{
    background-color: var(--tg-link);
}

.MenuHighlight.Vehicles{
    background-color: var(--tg-purple);
}

.MenuHighlight.Wallet{
    background-color: var(--tg-dark-purple);
}

.MenuHighlight .IconWrapper {
    width: 36px;
    height: 36px;
    border-radius: 4px;
    background-color: var(--tg-white15);
}

.MenuHighlightsNewWrapper{
    width: 32px;
    height: 15px;
    border-radius: 3px 12px 3px 3px;
    background-color: var(--tg-error);
    display: flex;
    font-size: 9px;
    color: var(--tg-white);
    line-height: 13px;
    font-weight: 500;
    justify-content: center;
}
.MenuHighlightsInfoIconWrapper{
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 70px;
    background-color: var(--tg-white15);
    border-top-right-radius: 20px;
    border-bottom-left-radius: 120px;
    display: flex;
    padding-top: 15px;
    align-items: flex-start;
    justify-content: center;
}
.CallCustomerServices{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100% - 250px);
}

.CallCustomerServices > a {
    color: var(--tg-primary-text);
    text-decoration: none;
    font-size: 16px;
}
.CallCustomerServices > a.tel {
    font-weight: 600;
}

.MenuList {
    margin: 16px 0;
}

.LogoutWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 15px;
}