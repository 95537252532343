ion-toast.custom-toast-occupation {
    --background: #EC4E2C;
    --max-height: 52px;
    width: 90%;
    left: 50%;
    top: 13%;
    transform: translateX(-50%);
    --border-radius: 35px;
    --border-color: #fff;
    --border-width: 3px;
    --border-style: solid;
}

ion-toast.custom-toast-occupation.mail {
    top: 23%;
}


ion-toast.custom-toast-occupation::part(icon) {
    font-size: 22px;
}

ion-toast.custom-toast-occupation::part(header) {
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    text-align: center;
}

ion-toast.custom-toast-occupation::part(message) {
    color: #166DF7;
    font-size: 14px;
    font-weight: 500;
    opacity: .8;
}

ion-toast.custom-toast-occupation::part(button) {
    color: #191E26;
    font-size: 12px;
    font-weight: normal;
    opacity: .7;
}