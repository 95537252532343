.SessionContent {
  width: 100%;
  height: 100%;
}

.SessionBackground {
  position: absolute;
}

.SessionContent .SessionHeader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 60px;
  z-index: 2;
}

.SessionContent .SessionHeader > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}

.SessionContent .SessionHeader .ChargingSpeed {
  background-color: var(--tg-black);
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  padding: 4px 8px;
}

.SessionHeader .TGBackButton .TGBackButtonIconWrapper {
  background-color: var(--tg-white);
  border: 1px solid var(--tg-inactive);
}

.SessionCar {
  width: auto;
  height: 100%;
  max-height: 429px;
  animation: car-animation 1.5s ease-in-out;
}

.box {
  width: 180px;
  max-height: 411px;
  height: 100%;
  position: absolute;
  overflow: hidden;
  clip-path: url(#clipPath);
  margin: 0 auto;
  bottom: 0;
  z-index: 1;
}

.main-liquid {
  content: "";
  position: absolute;
  z-index: 2;
  width: 370px;
  height: 460px;
  background: var(--tg-primary);
  opacity: .5;
  left: -27%;
  top: 85px;
  transform: rotate(-50deg);
  border-radius: 40%;
  animation: fill-reverse 3.5s ease-in-out infinite, initializingCarAnimation 3.5s ease-in-out;
  scale: .9;
}

.secondary-liquid {
  content: "";
  position: absolute;
  left: -15%;
  z-index: 1;
  top: 85px;
  width: 400px;
  height: 500px;
  background: var(--tg-primary);
  border-radius: 40%;
  opacity: .7;
  transform: rotate(-40deg);
  animation: fill 3.5s ease-in-out infinite, initializingCarAnimation 3.5s ease-in-out;
  scale: .9;
}

.SessionCar.--parking{
  width: 218px;
  height: 429px;
  scale: .7;
  animation: car-animation-parking 1.7s ease-in-out;
  transform: translateY(-70px);
}

.box.--parking{
  width: 180px;
  height: 411px;
  position: absolute;
  overflow: hidden;
  clip-path: url(#clipPath);
  margin: 0 auto;
  scale: .7;
  z-index: 1;
  transform: translateY(-40px);
}

.main-liquid.--parking {
  content: "";
  position: absolute;
  z-index: 2;
  width: 370px;
  height: 460px;
  background: var(--tg-park);
  opacity: .5;
  left: -27%;
  top: 85px;
  transform: rotate(-50deg) translateX(-45px);
  animation: fill-reverse 3.5s ease-in-out infinite, initializingCarAnimation 3.5s ease-in-out;
  scale: .7;
}
.main-liquid.--parking.--charging {
  background: var(--tg-park-charge);
}

.secondary-liquid.--parking {
  content: "";
  position: absolute;
  left: -15%;
  z-index: 1;
  top: 85px;
  width: 400px;
  height: 500px;
  background: var(--tg-park);
  opacity: .7;
  transform: rotate(-40deg) translateX(-45px);
  animation: fill 3.5s ease-in-out infinite, initializingCarAnimation 3.5s ease-in-out;
  scale: .7;
}

.secondary-liquid.--parking.--charging {
  background: var(--tg-park-charge);
}


.ParkingArea {
  width: 260px;
  height: 430px;
  position: absolute;
  scale: .8;
  animation: initiailizingParkingArea 1.7s ease-in-out; 
  opacity: 1;
}

.ChargingText {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 7px;
  left: 50%;
  bottom: 80px;
  transform: translateX(-50%);
  animation: initiailizingLabel 3.5s ease-in-out; 
  z-index: 2;
}

.top-animated {
  position: relative;
  height: calc(100% - 270px);
}
.SessionTopWrapper{
  position: relative;
  z-index: 4;
  animation: initiailizingTopLabels 1s ease-in-out;
}
.SessionInfoButton {
  color: #166DF7;
}

.SessionTopWrapper .TopLabels {
  display: grid;
  gap: 7px;
  grid-template-columns: calc(50% - 10px) 11px 81px auto;
  align-items: center;
  margin-bottom: 8px;
}

.SessionTopWrapper .TopLabels .charge-speed,.ActiveSessionButton .charge-speed {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 3px;
}

.top-animated .ChargeVehicleWrapper {
  width: 100%;
  height: calc(100% - 150px);
  max-height: 429px;
  position: relative;
  z-index: 4;
  margin-top: 25px;
  text-align: center;
}

.SessionTopWrapper .BottomLabelsWrapper{
  margin: 0 auto;
  max-width: 70%;
  background-color: #F6CB12;
}

.ModalTopLabelsWrapper {
  max-width: 85%;
  background-color: #fafafa;
}

.SessionTopWrapper .BottomLabelsWrapper, .ModalTopLabelsWrapper {
  display: flex;
  align-items: center;
  border-radius: 8px;
  justify-content: space-between;
  overflow: hidden;
  padding: 7px 9px;
}

.SessionTopWrapper .BottomLabelsWrapper.active {
  background-color: #fafafa;
  animation: heightChange 1s ease-in-out forwards;
}

@keyframes heightChange {
  0% {
    height: 0;
    padding: 0  9px;
  }
  100% {
    padding: 7px  9px;
    height: 30px;
  }
}

.SessionTopWrapper .BottomLabelsWrapper > div, .ModalTopLabelsWrapper > div {
  display: flex;
  align-items: center;
}

.SessionTopWrapper .BottomLabelsWrapper .circle-icon, .ModalTopLabelsWrapper .circle-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--tg-primary);
  margin: 0 5px;
}

.SessionTopWrapper .BottomLabelsWrapper .divider, .ModalTopLabelsWrapper .divider {
  width: 1px;
  height: 18px;
  background-color: var(--tg-gray-2);
  margin: 0 5px;
}

.SessionTopWrapper .OccupiedWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #EC4E2C;
    border-radius: 20px;
    padding: 10px;
    color: #fff;
    width: fit-content;
    margin: 10px auto 0;
}

.SessionTopWrapper .OccupiedWrapper .ElapsedTimeOccupation {
    display: inline-block;
    margin: 0 5px;
    padding: 0;
}

.TopLabels .divider {
  width: 1px;
  height: 30px;
  margin: auto;
  opacity: .5;
  background-color: var(--tg-gray-2);
}

.charge-speed {
  fill: red;
}


.BottomLabels {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  animation: initiailizingBottomLabels 4s ease-in-out;
}

.ElapsedTimeText {
  font-size: 18px;
  font-weight: 500;
  color: var(--tg-secondary-text);
  margin: 0 !important;
  padding: 0;
}

.ElapsedTimeText {
  transform: translateY(0);
}

.ActiveSessionButton .ElapsedTimeText {
  font-size: 22px;
  font-weight: 600;
  width: 34%;
}

svg {
  backface-visibility: hidden;
  transform: translateZ(0); /* Bu da animasyon veya transform problemlerini çözmeye yardımcı olabilir */
}

.InformationModalContent {
  padding: 24px 0 16px 0;
}

.InformationModalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.InformationModalHeader .ChargingLabel {
  background-color: var(--tg-primary);
  width: 138px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  flex-direction: row;
  border-radius: 32px;
}

.info-section {
  border: 1px solid var(--tg-inactive);
  padding: 10px;
  border-radius: 10px;
}

.info-line {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.charge-info .info-line {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
}

.ActiveSessionButton.active{
  transform: translateY(0);
}

.ActiveSessionButton{
  transform: translateY(calc(100px));
  transition: all 0.3s ease-in-out;
}

.ActiveSessionButton {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 100px;
  background-color: var(--tg-white);
  border-radius: 15px 15px 0 0;
  display: flex;
  padding: 0 38px;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(9, 9, 9, .12) 0 2px 4px;
}

.charge-info .info-line div {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 8px;
}

.info-line:not(:last-child) {
  margin-bottom: 8px;
}

.AnimatedVehicleContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SessionChargingSpeed{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.ActiveChargeAnimation {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  scale: 1.3;
  z-index: -1;
  animation: initiailizingBottomLabels linear 500ms 2s forwards;
}


@media only screen and (min-height: 750px) {

  .main-liquid {
    content: "";
    position: absolute;
    z-index: 2;
    width: 370px;
    height: 460px;
    background: var(--tg-charge);
    opacity: .5;
    left: -27%;
    top: 85px;
    transform: rotate(-50deg);
    border-radius: 40%;
    animation: fill-reverse 3.5s ease-in-out infinite, initializingCarAnimation 3.5s ease-in-out;
    scale: 1.2;
  }

  .secondary-liquid {
    content: "";
    position: absolute;
    left: -15%;
    z-index: 1;
    top: 85px;
    width: 400px;
    height: 500px;
    background: var(--tg-charge);
    border-radius: 40%;
    opacity: .7;
    transform: rotate(-40deg);
    animation: fill 3.5s ease-in-out infinite, initializingCarAnimation 3.5s ease-in-out;
    scale: 1.2;
  }
  
  .SessionCar.--parking{
    width: 218px;
    height: 429px;
    animation: car-animation-parking 1.7s ease-in-out;
    scale: .8;
    transform: translateY(-100px);
  }

  .box.--parking {
    width: 180px;
    height: 411px;
    position: absolute;
    overflow: hidden;
    clip-path: url(#clipPath);
    margin: 0 auto;
    scale: .8;
    z-index: 1;
    transform: translateY(30px) translateY(-100px);
  }

  .main-liquid.--parking {
    content: "";
    position: absolute;
    z-index: 2;
    width: 370px;
    height: 460px;
    background: var(--tg-park);
    opacity: .5;
    left: -27%;
    top: 85px;
    transform: rotate(-50deg) translateY(-100px);
    animation: fill-reverse 3.5s ease-in-out infinite, initializingCarAnimation 3.5s ease-in-out;
    scale: .8;
  }

  .main-liquid.--parking.--charging {
    background: var(--tg-park-charge);
  }
  

  .secondary-liquid.--parking {
    content: "";
    position: absolute;
    left: -15%;
    z-index: 1;
    top: 85px;
    width: 400px;
    height: 500px;
    background: var(--tg-park);
    opacity: .7;
    transform: rotate(-40deg) translateY(-100px);
    animation: fill 3.5s ease-in-out infinite, initializingCarAnimation 3.5s ease-in-out;
    scale: .8;
  }
  

  .secondary-liquid.--parking.--charging {
    background: var(--tg-park-charge);
  }


  .ParkingArea {
    width: 260px;
    height: 430px;
    position: absolute;
    scale: 1.2;
    transform: translateY(100px)
  }


}