.Onboarding {
    --background: #F6F6F6;
    --padding-top: 30px;
    --padding-bottom: 30px;
}

.Onboarding > div {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.OnboardingSlide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.OnboardingSlideContent {
    width: 100%;
    height: 416px;
    border-radius: 226px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.OnboardingSlideContent.bg-active::before {
    content: '';
    width: 226px;
    height: 100%;
    border-radius: 113px;
    background-color: #EBECED;
}

.OnboardingSlideTextContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*
    ---
    CSS of first slide
    ---
*/

.OnboardingParkingArea {
    fill: var(--tg-primary);
    margin: 0 auto;
}

.AnimatedOnboardingCar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.AnimatedOnboardingCar.animate {
    animation: carToParking 2s cubic-bezier(0.35, -0.27, 0, 0.26);
}

@keyframes carToParking {
    from {
        transform: translate(-50%, 150%);
    }

    to {
        transform: translate(-50%, -50%);
    }
}

/*
    ---
    CSS of second slide
    ---
*/
.OnboardingSlideContent .OnboardingMapPoints {
    height: 100%;
}

.OnboardingSlideContent .OnboardingMapPoints img {
    position: absolute;
    transform: scale(0);
}

.OnboardingSlideContent .OnboardingMapPoints.animate img {
    animation: OnboardingMapPoint 0.5s ease-in-out 0.5s forwards;
}

.OnboardingSlideContent .OnboardingMapPoints.animate .OnboardingMapPointFirst {
    top: 35%;
    left: 55%;
    animation-delay: .7s;
}

.OnboardingSlideContent .OnboardingMapPoints.animate .OnboardingMapPointSecond {
    top: 22%;
    left: 40%;
    animation-delay: .9s;
}

.OnboardingSlideContent .OnboardingMapPoints.animate .OnboardingMapPointThird {
    top: 57%;
    left: 45%;
    animation-delay: .4s;
}

@keyframes OnboardingMapPoint {
    0% {
        transform: scale(0)
    }
    60% {
        transform: scale(1.4)
    }
    100% {
        transform: scale(1)
    }
}

/*
    ---
    CSS of third slide
    ---
*/

.OnboardingSecondSlide_Car {
    position: absolute;
    top: 116px;
    z-index: 2;
}

.OnboardingSecondSlide_Car.animate {
    animation: carScaleAnimation .5s ease-in-out;
}

.OnboardingSecondSlide_CarLights {
    position: absolute;
    z-index: 9;
    top: 138px;
    margin: 0 auto;
    transform: translateX(35px);
    opacity: 0;
}

.OnboardingSecondSlide_CarLights.animate {
    animation: carLightAnimation;
    animation-delay: .7s;
    animation-duration: .15s;
    animation-iteration-count: 2;
    animation-fill-mode: forwards;
}

.OnboardingSecondSlide_Charge {
    position: absolute;
    top: 296px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
}

.OnboardingSecondSlide_Charge_firstLine {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.OnboardingSecondSlide_Charge.animate {
    animation: carLightAnimation .8s ease-in-out;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

@keyframes carScaleAnimation {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

@keyframes carLightAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/*
    ---
    CSS of fourth slide
    ---
*/
.OnboardingLastSlidePhone{
    position: absolute;
    z-index: 6;
}

.OnboardingLastSlideRoad{
    position: absolute;
    z-index: 7;
    transform: translateY(-48px) translateX(3.5px) scaleY(.7) scaleX(.67);
}

.OnboardingLastSlideCar{
    position: absolute;
    z-index: 8;
    transform: translateY(-127px) translateX(36px) scale(0.3)
}

.OnboardingNextButton{
    width: 58px;
    height: 58px;
    background-color: var(--tg-primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

/*
    ---
    CSS of five slide
    ---
*/
.OnboardingSlideWallet {
    font-weight: 700;
    font-size: 38px;
    color: var(--tg-primary-text);
}
.OnboardingSlideWallet span {
    color: var(--tg-white);
    background-color: var(--tg-dark-purple);
    padding: 0 5px;
    border-radius: 4px;
    margin-left: -5px;
}
.walletList {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    gap: 8px;
}
.walletList span {
    font-size: 17px;
    color: #000000;
    font-weight: 500;
}
