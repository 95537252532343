
/* TGAlert */
.TGAlert {
    position: fixed;
    top: -4rem;
    left: 50%;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding: 16px 18px;
    min-height: 54px;
    width: calc(100vw - 32px);
    border: none;
    border-radius: 12px;
    background-color: #fffaef;
    z-index: 40000004;
    transition: all 0.3s ease-out;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0.8px 2px;
}

.TGAlert.open {
    top: 1rem;
    opacity: 1;
}

.TGAlertIcon {
    display: block;
    flex-shrink: 0;
    height: 24px;
    width: 24px;
    margin: 0 12px 0 0;
    border-radius: 50%;
}

.TGAlert div {
    margin-right: 8px;
    color: var(--tg-white);
    font-size: 14px;
    font-weight: 600;
}

.TGAlert button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: fit-content;
    text-align: center;
    margin: 0 0 0 auto;
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--tg-white);
    font-size: 12px;
    font-weight: 500;
}

.TGAlert.-success {
    background-color: #38B35A;
    
}

.TGAlert.-success .TGAlertIcon {
    background-color: #38bc8e;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20id%3D%22Group_85336%22%20data-name%3D%22Group%2085336%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2224.492%22%20height%3D%2224.492%22%20viewBox%3D%220%200%2024.492%2024.492%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_2058%22%20data-name%3D%22Ellipse%202058%22%20cx%3D%2212.246%22%20cy%3D%2212.246%22%20r%3D%2212.246%22%20transform%3D%22translate(0%200)%22%20fill%3D%22%23fff%22/%3E%0A%20%20%3Cpath%20id%3D%22Path_91260%22%20data-name%3D%22Path%2091260%22%20d%3D%22M31.149%2C212.27l2.886%2C2.887%2C5.773-5.773%22%20transform%3D%22translate(-23.561%20-199.427)%22%20fill%3D%22none%22%20stroke%3D%22%2338b35a%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%221.458%22/%3E%0A%3C/svg%3E");
    background-position: center;
    background-size: 24px;
}

.TGAlert.-error {
    background-color: #EC4E2C;
}

.TGAlert.-error .TGAlertIcon {
    background-color: #e03232;
    background: url("data:image/svg+xml,%3Csvg%20id%3D%22Group_118951%22%20data-name%3D%22Group%20118951%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224.492%22%20height%3D%2224.492%22%20viewBox%3D%220%200%2024.492%2024.492%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_2058%22%20data-name%3D%22Ellipse%202058%22%20cx%3D%2212.246%22%20cy%3D%2212.246%22%20r%3D%2212.246%22%20transform%3D%22translate%280%200%29%22%20fill%3D%22%23fff%22%2F%3E%0A%20%20%3Cpath%20id%3D%22Path_91260%22%20data-name%3D%22Path%2091260%22%20d%3D%22M33.615%2C215.156l5.773-5.773%22%20transform%3D%22translate%28-24.255%20-200.023%29%22%20fill%3D%22none%22%20stroke%3D%22%23ec4e2c%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%221.458%22%2F%3E%0A%20%20%3Cpath%20id%3D%22Path_223393%22%20data-name%3D%22Path%20223393%22%20d%3D%22M39.388%2C215.156l-5.773-5.773%22%20transform%3D%22translate%28-24.255%20-200.023%29%22%20fill%3D%22none%22%20stroke%3D%22%23ec4e2c%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%221.458%22%2F%3E%0A%3C%2Fsvg%3E");
    background-position: center;
    background-size: 24px;
}

.TGAlert.-info {
    background-color: #4A90E2;
}

.TGAlert.-info .TGAlertIcon {
    background-color: #4A90E2;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24.492" height="24.491" viewBox="0 0 24.492 24.491"><circle id="Ellipse_2058" data-name="Ellipse 2058" cx="12.246" cy="12.246" r="12.246" transform="translate(0 0)" fill="%23fff"/><g id="Group_85353" data-name="Group 85353" transform="translate(12.244 8.356)"><path id="Path_91260" data-name="Path 91260" d="M33.615,213.666V208.23" transform="translate(-33.615 -205.886)" fill="none" stroke="%234a90e2" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.458"/><path id="Path_92095" data-name="Path 92095" d="M33.615,208.23v0" transform="translate(-33.615 -208.23)" fill="none" stroke="%234a90e2" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.458"/></g></svg>');
    background-position: center;
    background-size: 24px;
}

.TGAlert.-warning {
    background-color: #FBA63C;
}

.TGAlert.-warning .TGAlertIcon {
    background-color: #FBA63C;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.492' height='21.168' viewBox='0 0 24.492 21.168'%3E%3Cg id='Group_120500' data-name='Group 120500' transform='translate(18676.29 -8302.537)'%3E%3Cpath id='Path_92093' data-name='Path 92093' d='M81.015,215.929,72.471,201.76a3.821,3.821,0,0,0-6.438,0l-8.544,14.169a3.342,3.342,0,0,0-.056,3.342,3.756,3.756,0,0,0,3.275,1.894H77.8a3.752,3.752,0,0,0,3.275-1.849,3.342,3.342,0,0,0-.056-3.386Zm-11.763.78a1.114,1.114,0,1,1,.788-.326A1.113,1.113,0,0,1,69.252,216.709Zm1.114-4.456a1.114,1.114,0,0,1-2.228,0V207.8a1.114,1.114,0,0,1,2.228,0Z' transform='translate(-18733.301 8102.54)' fill='%23fff'/%3E%3Cpath id='Path_92094' data-name='Path 92094' d='M66.493,215.051a1.114,1.114,0,1,1,.788-.326A1.113,1.113,0,0,1,66.493,215.051Zm1.114-4.456a1.114,1.114,0,0,1-2.228,0V206.14a1.114,1.114,0,0,1,2.228,0Z' transform='translate(-18730.543 8104.198)' fill='%23fba63c'/%3E%3C/g%3E%3C/svg%3E") no-repeat center center;
    background-position: center;
    background-size: 24px;
    border-radius: 0;
}

/* TGAlertModal */
.TGAlertModal {
    z-index: 24000 !important;
}

.TGAlertModal::part(content) {
    --background: var(--dt-page-background);
    --border-radius: 18px;

    height: 300px;
    width: 90vw;
    box-sizing: border-box;
}

.TGAlertModal::part(scroll) {
    padding: 16px;
}

.TGAlertModal::part(scroll)::-webkit-scrollbar {
    display: none;
}

.TGAlertModalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: auto;
    padding: 20px;
}

.TGAlertModal .Text {
    display: flex;
    justify-content: center;
    text-align: center;
}
