/* Custom Toger Dialog */
@import "./TGDialog.css";

.TGPageContent{
    width: 100%;
}
.TGMapPageContent.iosSafeAreaTopPadding{
    --padding-top: 0 !important;
}
.TGPageHeader {
    --background: var(--tg-white);
    --border-width: 0 0 !important;
    --padding-start: 16px;
    --padding-end: 16px;
}
.TGPageHeader ion-back-button{
    --color: var(--tg-secondary-text);
    --min-width: fit-content;
}
.TGLogo.KeyboardActive{
    transform: scale(0.7);
    translate: all 0.3s;
}
.TGInputWrapper{
    position: relative;
    flex-shrink: 0;
    width: 100%;
    --background: var(--tg-white);
    --color	: var(--tg-primary);
    --padding-start: 0;
    --inner-padding-top: 37px;
    --inner-padding-bottom: 30px;
    --border-width: 0px;
}
.TGSearchModalInputWrapper{
    display: flex;
    width: 100%;
    height: 45px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    position: relative;
    flex-shrink: 0;
    --background: transparent;
    --color	: var(--tg-primary);
    --padding-start: 0;
    --inner-padding-top: 0px;
    --inner-padding-bottom: 0px;
    --border-width: 0px;
    --inner-padding-end: 0;
}
.TGSearchModalInputWrapper .TGInputBorder{
    width: 100%;
    height: 40px;
    border-radius: 40px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--tg-inactive-border);
    padding-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: #fff;
}

.TGSearchModalInputWrapper .TGInputBorder.error{
    border: 1px solid var(--tg-error) !important;
}
.TGSearchModalInputWrapper .TGInput{
    color: var(--tg-primary-text);
    font-weight: medium;
    font-size: 16px;
    line-height: 17px;
    letter-spacing: 0px;
}
.TGSearchModalInputWrapper .TGInput{
    --padding-end: 32px;
}
.TGSearchModalInputWrapper .TGInputLabel{
    font-weight: medium;
    font-size: 20px;
    color: var(--tg-secondary-text);
    position: absolute;
    left: 16px;
    background-color: var(--tg-white);
    transform: translateY(0);
    transition: all 0.1s ease;   
    z-index: 999;
    padding: 0 8px;
    text-transform: capitalize;
}
.TGSearchModalInputWrapper .TGInput.focus+.TGInputLabel{
    transform: translate(10px, -30px);
    font-size: 16px;
}

/* --------------- */

.TGInputWrapper .TGInputBorder{
    border: 1px solid var(--tg-inactive-border);
    width: 100%;
    height: 60px;
    border-width: 1px;
    border-style: solid;
    padding-left: 19px;
    padding-right: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}
.TGInputWrapper .TGInputBorder.error{
    border: 1px solid var(--tg-error) !important;
}
.TGInputWrapper .TGInputBorder.focus{
    border: 1px solid var(--tg-primary-text);
}
.TGInputWrapper .TGInput{
    color: var(--tg-primary-text);
    font-weight: semibold;
    font-size: 18px;
    line-height: 17px;
    letter-spacing: 0px;
}
.TGInputLabel{
    font-weight: medium;
    font-size: 20px;
    color: var(--tg-secondary-text);
    position: absolute;
    left: 16px;
    background-color: var(--tg-white);
    transform: translateY(0);
    transition: all 0.1s ease;   
    z-index: 999;
    padding: 0 8px;
    text-transform: capitalize;
    pointer-events: none;
}
.TGInputLabel.--error{
    color: var(--tg-error);
}
.TGInputLabel.focus{
    transform: translate(0, -30px);
    font-size: 16px;
}
.TGInputSubtext{
    position: absolute;
    bottom: -30px;
}
.TGButton{
    width: 100%;
    height: 55px;
    font-size: 18px;
    font-weight: medium;
    color: var(--tg-white);
    display: flex;
    justify-content: center;
    align-items: center;
}
.TGMapButton:not(.ScanButton)::part(native) {
    --box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
    --padding-top: 0;
    --padding-start: 0;
    --padding-end: 0;
    --padding-bottom: 0;
    margin: 0;
}
.TGMapButton svg {
    flex-shrink: 0;
}

.TGMapButtonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    transition: all 0.3s ease-in-out;
}

.TGMapButtonWrapper.activeSession {
    margin-bottom: 90px;
}

.TGMapButtonWrapper > .anniversaryIcon.activeSession {
    opacity: 0;
}


.TGMapScanText{
    transition: all 0.3s ease-in-out;
}

.TGMapScanText.activeSession {
    opacity: 0;
}



.TGMapFilterButton::part(native) {
    --box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.1);
    --padding-top: 0;
    --padding-start: 5px;
    --padding-end: 5px;
    --padding-bottom: 0;
}
.TGMapFilterButton svg {
    flex-shrink: 0;
}
.TGSpinner{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.TGSpinnerWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    animation: rotation 1s infinite linear;
}
.TGSpinnerWrapper .TGSpinnerWhite{
    display: inline-block;
    width: 80%;
    height: 80%;
    border-radius: 80%;
    background: var(--tg-white);
}
.TGSpinnerText {
    position: absolute;
    font-size: 22px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}
@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

.TGLink {
    text-decoration: none;
    display: flex;
    align-items: center;
}

.TGFormProgressWrapper {
    display: flex;
    width: 280px;
    margin: 20px auto;
    align-items: center;
}
.TGFormProgress {
    width: 240px;
    height: 5px;
    border-radius: 10px;
    margin: 0 auto;
}
.TGFormProgressSteps {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 280px;
    height: 40px;
    margin: 0 auto;
}
.TGFormProgressStep {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border: none;
    background-color: var(--tg-transperant);
    border-radius: 48px;
}  
.TGFormProgressStep.InUse {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border: 1px solid var(--tg-primary);
    background-color: var(--tg-white);
    border-radius: 48px;
}  
.TGFormProgressStepInner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: var(--tg-inactive);
    border-radius: 48px;
}  
.TGFormProgressStepInner.active {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: var(--tg-primary);
    border-radius: 40px;
}  
.TGSearchIcon{
    /*position: absolute;*/
    /*right: 16px;*/
    margin-right: 5px;
    flex-shrink: 0;
}
/*.TGSearchIcon.clear{*/
/*    position: absolute;*/
/*    right: 16px;*/
/*    z-index: 1000;*/
/*}*/

.TGUser{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--tg-primary-text);
    border-radius: 20px;
    padding-left: 20px;
    gap: 10px;
    position: relative;
    z-index: 9999999;
    top: 0;
}
.UserIconWrapper{
    background-color: var(--tg-gray);
    width: 60px;
    height: 60px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.TGUser .GreenBadge{
    border-radius: 5px;
    background-color: var(--tg-primary);
    color: var(--tg-white);
    padding: 1px 3px;
}
.TGUser .UserEditButton{
    position: absolute;
    top: 15px;
    right: 22px;
}
.TGAddVehicleButton {
    width: 100%;
    height: 100px;
    border: 1px solid var(--tg-inactive-border);
    border-radius: 20px;
}
.TGPlus{
    background-color: var(--tg-primary-text);
    width: 36px;
    height: 36px;
    border-radius: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.TGVehicle{
    background-color: var(--tg-white);
    border: 1px solid var(--tg-inactive-border);
    border-radius: 20px;
    display: flex;
    width: 100%;
    height: 113px;
    justify-content: space-between;
    align-items: center;
}
.TGVehicleInfoText{
    display: flex;
    flex-direction: column;
}

.IconWrapper {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: var(--tg-primary-text);
    border-radius: 40px;
    margin-right: 10px;
}
.TGHeaderToolbar{
    --background: var(--tg-white);
}
.TGBottomToolbar{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    overflow-x: hidden;
    width: 100%;
    padding: 0 13% 25px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF00 47%, #FFFFFFE6 100%);
    transform: translateY(400px);
    transition: all 0.3s ease-in-out;
}
.TGBottomToolbar.active{
    transform: translateY(0);
}

.TGBottomToolbar:has( > div.activeSession) {
    background: transparent;
}



.TGMapScanAnimation {
    position: absolute;
    width: 92px;
    height: 92px;
}
.TGFab{
    position: relative;
}
.TGFab.expanded .TGFabButton{
    width: 40px;
}
.TGFabButton{
    width: 70px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    --background: var(--tg-white);
    --background-activated: #ededed;
    --background-hover: #ededed;
    --border-radius: 40px;
    --box-shadow: 0px 2px 4px #0000002c;
    --color: black;
}
.TGFabList{
    margin: 41px 0px 0px -9px;
}
.TGNotificationsButton{
    position: relative;
}

.TGNotificationsButton .NotificationDot {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    border: 1.5px solid var(--tg-white);
    background-color: var(--tg-error);
    position: absolute;
    top: 4px;
    right: 5px;
}

.TGModal.modal-sheet:not(.fullscreen){
    --border-radius: 15px 15px 0 0;
}

.TGModal::part(handle){
    z-index: 9999;
}

.TGModal::part(handle)::before{
    padding-top: 30px;
    padding-bottom: 30px;
    padding-inline: 30px;
}

.TGBackButton .TGBackButtonIconWrapper{
    width: 34px;
    height: 34px;
    border-radius: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--tg-inactive);
    margin-right: 8px;
    padding-left: 3px;
}

.TGList .List{
    margin-top: 10px;
}
.TGList .TGItem, .TGList .TGHalfItem{
    background-color: var(--tg-item-gray50);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 66px;
    padding: 16px 14px;
}

.TGList .TGItem{
    width: 100%;
}

.TGList .TGHalfItem{
    width: calc((100% - 10px) / 2);
    border-radius: 20px;
    display: inline-flex;
    padding: 16px 14px
}


.TGList:not(.Menu) .List .TGItem:first-child{
    border-radius: 21px 21px 0 0;
}
.TGList:not(.Menu) .List .TGItem:last-child{
    border-radius: 0 0 21px 21px;
}
.TGList:not(.Menu) .TGItem:not(:last-child){
    margin-bottom: 2px;
}

.TGList.Menu .List{
    margin-top: 0px;

}
.TGList.Menu .TGItem{
    border-radius: 20px;
    padding: 16px 14px;
    width: 100%;
    height: 66px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--tg-item-gray50);
}

.TGList.Menu .TGItem-dark{
    background-color: var(--tg-item-dark);
}

.TGList.Menu .TGItem-blue{
    background-color: var(--tg-park);
}

.TGList.Menu .TGItem-transparent{
    background-color: transparent;
    border: 1px solid #E4E4E4;
}

.TGList.Menu .TGItem:not(:last-child),.TGList.Menu .TGHalfItem:not(:last-child){
    margin-bottom: 6px;
}
.TGItemIconWrapper{
    background-color: var(--tg-item-gray);
    width: 36px;
    height: 36px;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.TGList .TGItem > div{
    display: flex;
    align-items: center;
    gap: 10px;
}

.TGItem:active .ItemArrow{
    transform: translateX(5px);
    transition: all 0.5s;
}

.TGRadio::part(container) {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: 2px solid #ddd;
}

.TGRadio::part(mark) {
    background: none;
    transition: none;
    transform: none;
    border-radius: 0;
}

.TGRadio.radio-checked::part(container) {
    background: var(--tg-link);
    border-color: var(--tg-transperant);
}

.TGRadio.radio-checked::part(mark) {
    width: 6px;
    height: 11px;
    border-width: 0px 2px 2px 0px;
    border-style: solid;
    border-color: var(--tg-white);
    transform: translateY(-1px) rotate(45deg);
}
.TGSelectWrapper{
    --background: var(--tg-item-gray50);
    --border-radius: 10px;
    --inner-padding-start: 16px;
    --padding-start: 0;
    --min-height: 58px;
}

.TGOTPInputContainer{
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.TGOTPInput{
    width: 40px !important;
    height: 40px;
    margin-right: 8px;
    margin-left: 8px;
    border-radius: 5px;
    border: 1px solid var(--tg-gray);
    outline: none;
}

.TGOTPInputSuccess{
    border: 1px solid var(--tg-primary);
}

.TGOTPInputError{
    border: 1px solid var(--tg-error);
}

.TGOTPInputFocus{
    border: 1px solid var(--tg-primary-text);
}
.TGAccordion{
    border-bottom: 1px solid var(--tg-gray);
}
.TGAccordionHeader {
    --background: transparent;
    
}

.accordion-expanded .TGAccordionHeader {
    --background: var(--tg-item-gray50);
}

.TGAccordionIcon{
    width: 10.2px;
    height: 10.2px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMC4yMDUiIGhlaWdodD0iMTAuMjA1IiB2aWV3Qm94PSIwIDAgMTAuMjA1IDEwLjIwNSI+PHBhdGggZD0iTTYuMjQ0LDExLjc2VjguMjI1SDIuNzA4YS43MzUuNzM1LDAsMSwxLDAtMS40NzFINi4yNDRWMy4wMjdhLjczNS43MzUsMCwxLDEsMS40NzEsMFY2Ljc1NGgzLjcyN2EuNzM1LjczNSwwLDEsMSwwLDEuNDcxSDcuNzE0VjExLjc2YS43MzUuNzM1LDAsMSwxLTEuNDcxLDBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMi40OTYgLTEuOTcyKSByb3RhdGUoOTApIiBmaWxsPSIjMTkxZTI2Ii8+PC9zdmc+");
}
.accordion-expanded .TGAccordionIcon{
    width: 10.2px;
    height: 1.5px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMC4yMDUiIGhlaWdodD0iMS40NzEiIHZpZXdCb3g9IjAgMCAxMC4yMDUgMS40NzEiPjxwYXRoIGQ9Ik02Ljk3OSwyLjI5MWEuNzM1LjczNSwwLDAsMSwuNzM1LjczNVYxMS43NmEuNzM1LjczNSwwLDAsMS0xLjQ3MSwwVjMuMDI3QS43MzUuNzM1LDAsMCwxLDYuOTc5LDIuMjkxWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTIuNDk2IC02LjI0Mykgcm90YXRlKDkwKSIgZmlsbD0iIzE5MWUyNiIvPjwvc3ZnPg==")
}
.ion-accordion-toggle-icon {
    display: none;
}

.TGAccordionContent {
    padding: 12px;
}

.TGAccordionContent p {
    margin: 0;
}

.TGTextareaContainer{
    --padding-start: 0px;
    --inner-padding-end: 0px;
}

.TGTextarea{
    margin-top: 10px;
    --border-color: var(--tg-gray);
    --border-width: 1px;
    --border-style: solid;
    --border-radius: 40px;
    --inner-padding-top: 37px;
    --inner-padding-bottom: 30px;
    --inner-padding-right: 16px;
    --inner-padding-left: 16px;
    border: 1px solid var(--tg-gray);
    border-radius: 13px;
    padding: 6px 16px;
}

.TGTextarea .textarea-bottom  {
    border-top: 0;
}

.TGTextarea.error .textarea-bottom .counter {
    color: var(--tg-error);
}

.TGTextarea .textarea-bottom .counter {
    font-size: 18px;
}

.TGTextarea.error{
    --border-color: var(--tg-error) !important;
    border: 1px solid var(--tg-error) !important;
}

.TGTextarea.focus {
    --border-color: var(--tg-primary-text);
    border: 1px solid var(--tg-primary-text);
}

.TGTextarea.has-focus {
    --border-color: var(--tg-primary-text);
    border: 1px solid var(--tg-primary-text);
}

.TGTextarea.has-value {
    --border-color: var(--tg-primary-text);
    border: 1px solid var(--tg-primary-text);
}

.TGTextareaLabel{
    font-weight: medium;
    font-size: 20px;
    color: var(--tg-secondary-text);
    position: absolute;
    left: 16px;
    top: 30px;
    background-color: var(--tg-white);
    transform: translateY(0);
    transition: all 0.1s ease;   
    z-index: 999;
    padding: 0 8px;
    text-transform: capitalize;
}

.TGTextareaLabel.error{
    color: var(--tg-error);
}

.TGTextarea.has-focus + .TGTextareaLabel{
    transform: translate(10px, -30px);
    font-size: 16px;
}

.TGTextarea.has-value + .TGTextareaLabel{
    transform: translate(10px, -30px);
    font-size: 16px;
}

.TGVersionLabel {
    display: flex;
    justify-content: end;
    gap: 4px;
    bottom: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: var(--ion-safe-area-bottom);
}

@media only screen and (min-width: 768px) {
    .TGVersionLabel {
        position: initial;
    }
}

@media only screen and (min-height: 750px) {
    .TGNotificationsButton .NotificationDot {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        border: 1.5px solid var(--tg-white);
        background-color: var(--tg-error);
        position: absolute;
        top: 4px;
        right: 5px;
    }
}

.TGBottomToolbar .MonoLogo {
    position: absolute;
    right: 16px;
    opacity: 0.25;
}

.loading-animation {
    animation: rotate-animation 1s infinite linear;
}

@keyframes rotate-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.button-disabled {
    opacity: 1;
    --background: #E9EAEA !important;
    --tg-white: #939598;
}