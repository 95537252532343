.NotificationsPage {
    --padding-start: 16px;
    --padding-end: 16px;
}

.NotificationsPage .clear-all-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    border-radius: 20px;
    background-color: var(--tg-gray-2);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
}

.NotificationsPage .NoEntries {
    gap: 30px;
}

.Notification {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 16px;
    background-color: transparent;
}

.Notification:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--tg-gray-3);
}

.Notification .circle-icon {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--tg-gray-line);
    margin-left: 10px;
    margin-right: 16px;
    flex-shrink: 0;
}

.Notification .circle-icon.active {
    background-color: var(--tg-primary);
}
.Notification .NotificationTextWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Notification .NotificationTitleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    margin-bottom: 4px;
}

.NotificationSlideButton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.NotificationsGroupWrapper:first-of-type {
    margin-top: 22px;
}

.NotificationsGroupWrapper:not(:last-child) {
    margin-bottom: 20px;
}

.NotificationsGroupWrapper .NotificationGroupDate {
}