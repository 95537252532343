ion-toast.dev-mode-toast {
    --background: var(--tg-error);
    --box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.2);
}

ion-toast.dev-mode-toast::part(icon) {
    font-size: 22px;
}

ion-toast.dev-mode-toast::part(header) {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
}

ion-toast.dev-mode-toast::part(button) {
    color: #191E26;
    font-size: 12px;
    font-weight: normal;
    opacity: .7;
}