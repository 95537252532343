
.TGDialog {
    --background: var(--tg-white);
    --min-width: 314px;
}

.TGDialog .alert-wrapper{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: unset;
    padding-top: 20px;
    padding-bottom: 35px;
}

.TGDialog .alert-message {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: var(--tg-primary-text);
    height: 100%;
}
.TGDialog .alert-button-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 14px;
}
.TGDialog .alert-button-group button{
    width: 120px;
    max-width: 120px;
    height: 48px;
    color: var(--tg-white);
    border-radius: 34px;
    flex: none;
    min-width: unset;
}
.TGDialog .alert-button-group button:first-child{
    background: #939598;
}
.TGDialog .alert-button-group button:nth-child(2){
    background: var(--tg-primary);
}
.TGDialog .alert-button-inner{
    justify-content: center;
}