.MapFilterButtons{
    position: absolute;
    gap: 10px;
    left: 0;
    top: 16px;
    width: 100%;
    transform: translateY(-150px);
    transition: all 0.3s ease-in-out;
}

.MapFilterButtons.no-story{
    left: 16px;
    display: flex;
}
.MapFilterButtons.active{
    transform: translateY(0);
}

.MapFilterButtons.ios{
    top: env(safe-area-inset-top);
}

.StorySliderContainer {
    width: calc(100%);
    position: relative;
    background-color: color-mix(var(--tg-black), var(--tg-white), 0.1);
}
.StorySlider {
    display: flex;
    overflow-x: auto;
    gap: 10px;
}
.StorySlider::-webkit-scrollbar {
    display: none;
}
.StorySliderItem{
    position: relative;
    width: 74px;
}

.StorySliderItem:first-child {
    margin-left: 16px;
}

.StorySliderItem:last-child {
    margin-right: 16px;
}

.StorySliderItemImageWrapper {
    width: 74px;
    height: 74px;
    border-radius: 74px;
    border: 2px solid red;
    transition: all ease-in-out .1s;
}

.StorySliderItemImageWrapper.viewed {
    border-color: #BEBFC1;
}

.StorySliderItemImageWrapper:active {
    scale: .9;
}

.StorySliderItemImageWrapper img {
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid transparent;
}

.StorySliderItemText {
    margin-top: 2px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 10px;
}

.SeeMoreButtonWrapper {
    max-width: 140px;
    margin: 0 auto 50px;
}

.SeeMoreButtonWrapper.passive {
    display: none;
}

.StoryHeader {
    margin-top: 10px;
    height: 46px;
    display: flex;
    align-items: center;
    width: 100%;
}

.StoryHeader .StoryHeaderCloseButton {
    margin-left: auto;
}

.StoryHeader .StoryHeaderImageWrapper {
    width: 46px;
    height: 100%;
    border-radius: 50%;
    border: 2px solid red;
}

.StoryHeader .StoryHeaderImageWrapper img {
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid transparent;
    width: 100%;
    height: 100%;
}

.StoryHeader .StoryHeaderText {
    margin-left: 10px;
    font-size: 13px;
    font-weight: 600;
    line-height: 19px;
    color: #fff;
}

.SeeMoreButton {
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 22px;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    border:1px solid #B9BBC5;
}


.upside-arrow-icon {
    position: absolute;
    width: 140px;
}

.upside-arrow-icon:after{
    animation: slide-up 2s infinite;
    content: "";
    position: absolute;
    top: -15px;
    left: calc(50% + 6.5px);
    transform: translateX(-50%) rotate(35deg);
    background-color: #B9BBC5;
    width: 18px;
    height: 3px;
    border-radius: 3px;
}

.upside-arrow-icon:before{
    animation: slide-up 2s infinite;
    content: "";
    position: absolute;
    top: -15px;
    left: calc(50% - 6.5px);
    transform: translateX(-50%) rotate(-35deg);
    background-color: #B9BBC5;
    width: 18px;
    height: 3px;
    border-radius: 3px;
}

@keyframes slide-up {
    0% {
        top: -15px;
    }
    50% {
        top: -25px;
    }
    100% {
        top: -15px;
    }
}

.MapFilterIconsWrapper{
    width: 27px;
    height: 27px;
    border-radius: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--tg-black);
}
.MapFilterIconsWrapper.--active{
    background-color: var(--tg-white);
}
.MapActionButtonsTop {
    display: flex;
    flex-direction: column;
    gap: 10px;
    transform: translateX(100px);
    transition: all 0.3s ease-in-out;
    justify-content: space-between;
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 999;
}
.MapActionButtonsTop.active {
    transform: translateX(0px);
}
.MapActionButtonsTop.ios{
    top: env(safe-area-inset-top);
}
.MapActionButtonsBottom{
    display: flex;
    flex-direction: column;
    gap: 10px;
    transform: translate(100px, -50%);
    transition: all 0.3s ease-in-out;
    justify-content: space-between;
    position: absolute;
    right: 16px;
    top: 50%;
    z-index: 999;
}
.MapActionButtonsBottom.active{
    transform: translate(0, -50%);
}

.TGMapScanButton {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: var(--tg-black);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: scale .1s linear;
}
.TGMapScanButton:active {
    scale: .9;
}

.TGMapScanButton.activeSession {
    outline: 2px solid var(--tg-primary);
}

.TGMapScanButton p sup {
    font-size: 18px;
    margin-right: 2px;
    vertical-align: sub;
}

.SearchModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 999;
    transform: translateY(-100%);
    transition: all 0.3s ease-in-out;
}

.SearchModal.active {
    transform: translateY(0);
}

.SearchModalContent {
    width: 100%;
    position: absolute;
    top: 0;
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0));
    padding-top: env(safe-area-inset-top);
    height: 100%;
    min-height: 354px;
    max-height: 550px;
}

.SearchModalContent:has(.SearchResultsItem:nth-child(3)) {
    padding-bottom: 80px;
    background: linear-gradient(180deg, rgba(255,255,255, 1) 0%, rgba(255,255,255,1) 78%, rgba(255,255,255,0));
}

