.SetVehicle{
    transform: translateX(1000px);
    transition: all 0.5s ease-in-out;
    position: absolute;
    width: calc(100% - 32px);
  }
  .SetVehicle.active{
    transform: translateX(0px);
  }
  .Models,
  .Brands {
    overflow-y: scroll;
  }
  .Brands{
    height: calc(100% - 183px);
  }
  .Models{
    max-height: calc(100vh - 360px);
  }
  .Brands.done{
    transform: translateX(-1000px);
  }
  
  .BrandItem{
    float: left;
    width: calc((100% / 3) - 10px);
    height: 105px;
    border-radius: 20px;
    border: 1px solid var(--tg-inactive-border);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 10px 20px 0;
  }
  .ModelItem{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
    border: 1px solid var(--tg-inactive-border);
    border-radius: 20px;
    padding-right: 22px;
    padding-left: 22px;
    gap: 10px;
    overflow: hidden;
  }
  .ModelItem:not(:last-child){
    margin-bottom: 10px;
  }
  .ModelName{
    display:inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .VehiclePreviewAnimated{
    height: 416px;
    width: 100%;
  }
  .VehiclePreviewAnimatedBackground{
    height: 416px;
    border-radius: 113px;
    margin: 0 auto;
    display: flex;
    background-color: var(--tg-white); 
    margin-top: 30px;
  }

.VehiclePreviewAnimatedInner{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}

.VehiclePreviewAnimatedInner_VehicleInfo{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.EnterPlateContent{
  display: flex;
  flex-direction: column;
}

.EnterPlateContent .TGInput {
  text-transform: uppercase;
}

.HiddenCar{
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(50%);
}
.HiddenCarImage{
  z-index: 1;
}

.HiddenCarCircleFirst{
  position: absolute;
  display: inline-block;
  min-width: 316px;
  min-height: 316px;
  border-radius: 316px;
  background-color: var(-tg-gray-3);
  opacity: 0.35;
}
.HiddenCarCircleSecond{
  position: absolute;
  display: inline-block;
  min-width: 252px;
  min-height: 252px;
  border-radius: 252px;
  background-color: var(-tg-gray-3);
  opacity: 0.50;
}
.HiddenCarCircleThird{
  position: absolute;
  display: inline-block;
  min-width: 180px;
  min-height: 180px;
  border-radius: 180px;
  background-color: var(-tg-gray-3);
  opacity: 1;
}

.BrandsSlide{
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
  margin-bottom: 16px;
}

.BrandsSlide .BrandItem{
  min-width: 91px;
}
.BrandsSlide .BrandItem.Active{
  background-color: var(--tg-white-2);
  border: 1.5px solid #18C941;
}
.VehicleList{
  padding-right: 5px;
  height: calc(100% - 340px);
  overflow-y: scroll;
}
.AddVehiclesPage .VehicleSlidingWrapper {
  width: 100%;
  height: 96px;
  border: 1px solid var(--tg-inactive-border);
  border-radius: 20px;
}
.VehicleSlidingOption{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AddVehiclesPage .VehicleItem {
  --inner-padding-start: 20px;
  --inner-padding-end: 20px;
  --inner-padding-top: 16px;
  --inner-padding-bottom: 16px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.AddVehiclesPage .VehicleSlidingWrapper:not(:last-child) {
  margin-bottom: 10px;
}

.AddVehiclesPage .VehicleItem .VehicleItemInner{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.AddVehiclesPage .VehicleItem .VehicleItemRight{
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 16px;
}
.MyVehicles {
  width: 100%;
  height: calc(100% - 16px);
  padding-bottom: 80px;
  overflow-y: auto;
  overflow-x: hidden;
}
.MyVehicle{
  background: var(--tg-white);
  border-color: var(--tg-inactive-border);
  border-style: solid;
  border-radius: 20px;
  border-width: 1px;
}
.MyVehicleItem{
  width: 100%;
  --min-height: 110px;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  --padding-start: 0;
  --border-width: 0;
  --inner-border-width: 0;
  --border: 0;
    --inner-border: 0;
  --outline:0
}

.item-sliding-active-slide .item {
  pointer-events: auto;
}

.MyVehicleItem .addVehicle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: fit-content;
    margin: 0 auto;
    border-radius: 6px;
    padding: 4px 12px;
    border: 1px dashed var(--tg-gray-2);
    color: var(--tg-gray-2);
}

.SelectBrandAndModelModal {
  padding-top: 24px;
}

.SelectBrandAndModelModal .Header-right {
  position: absolute;
  padding-top: 3px;
  right: 16px;
}

.SelectBrandAndModelModal .Header-left {
  position: absolute;
  padding-top: 3px;
}

.MyVehicleItem .VehicleImage {
  flex-shrink: 0;
}

.MyVehicleItem .brandLogo {
  object-fit: contain;
}

.MyVehicleWrapper .vehicleDefaultButton {
  margin-left: auto;
  align-self: flex-start;
  padding: 16px 16px 16px 0;
  color: var(--tg-gray-6);
}

.MyVehicleWrapper .vehicleDefaultButton .moreButton {
  text-align: right;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--tg-error);
  line-height: 0;
}

.firstVehicleAnimation .MyVehicle {
  animation: slideIn .7s linear 0.2s;
}

@keyframes slideIn {
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(-150px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.MyVehicleWrapper{
  background-color: var(--ion-color-base);
  border-radius: 20px;
  position: relative;
}

.MyVehicleWrapper .OptionsItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;
  width: 60px;
}

.MyVehicleWrapperBorder{
  border-color: var(--tg-inactive-border);
  border-style: solid;
  border-width: 1px 0 1px 1px;
  border-radius: 20px 0 0 20px;
  height: 100%;
  width: 10%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 3;
}

.MyVehicleWrapperBorder.Active{
  border-color: var(--tg-primary);
}

.MyVehicleWrapper:not(:last-child){
  margin-bottom: 12px;
}
.MyVehicle.DefaultVehicle{
  border-color: var(--tg-primary);
}

.MyVehicleItem > div {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
}
.MyVehicleItem > div > div > div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 8px;
}
.VehicleImage{
  margin-left: -25px;
}
.ModelInfo {
  width: 125px;
}