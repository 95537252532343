.ios .iosSafeAreaMargin {
    margin-top: constant(safe-area-inset-top); /* Status bar height on iOS 11.0 */
    margin-top: env(safe-area-inset-top); /* Status bar height on iOS 11+ */
    margin-bottom: constant(
        safe-area-inset-bottom
    ); /* Status bar height on iOS 11.0 */
    margin-bottom: env(safe-area-inset-bottom); /* Status bar height on iOS 11+ */
}
.ios .iosSafeAreaPadding {
    padding-top: constant(safe-area-inset-top); /* Status bar height on iOS 11.0 */
    padding-top: env(safe-area-inset-top); /* Status bar height on iOS 11+ */
    padding-bottom: constant(safe-area-inset-bottom); /* Status bar height on iOS 11.0 */
    padding-bottom: env(safe-area-inset-bottom); /* Status bar height on iOS 11+ */
}
.ios .iosSafeAreaTopPadding {
    padding-top: constant(safe-area-inset-top); /* Status bar height on iOS 11.0 */
    padding-top: env(safe-area-inset-top); /* Status bar height on iOS 11+ */
    --padding-top: constant(safe-area-inset-top); /* Status bar height on iOS 11.0 */
    --padding-top: env(safe-area-inset-top); /* Status bar height on iOS 11+ */
}
.ios .iosSafeAreaTopMargin {
    margin-top: constant(safe-area-inset-top); /* Status bar height on iOS 11.0 */
    margin-top: env(safe-area-inset-top); /* Status bar height on iOS 11+ */
}
.ios .iosSafeAreaBottomPadding {
    padding-bottom: constant(safe-area-inset-bottom); /* Status bar height on iOS 11.0 */
    padding-bottom: env(safe-area-inset-bottom); /* Status bar height on iOS 11+ */
}

