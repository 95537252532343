@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

:root {

   /** TG Variables **/

   /* Colors */
   --tg-primary: #18C941;
   --tg-primary-200: rgba(24, 201, 65, 0.2);
   --tg-primary-300: rgba(24, 201, 65, 0.3);
   --tg-primary-text: #191e26;
   --tg-secondary-text: #939598;
   --tg-secondary-text50: #93959880;
   --tg-link: #166DF7;
   --tg-inactive: #DEDEDF;
   --tg-inactive-border: #dfdfe0;
   --tg-gray: #DEDEDE;
   --tg-gray-2: #939598;
   --tg-gray-3: #EBECED;
   --tg-gray-4:#EAEAEA;
   --tg-gray-5:#ECECEC;
   --tg-gray-6:#9395984D;
   --tg-error: #C91818;
   --tg-purple: #8716F7;
   --tg-purple-2: #8f26f9;
   --tg-dark-purple: #711DB1;
   --tg-soft-purple:#EBD6FF;
   --tg-orange: #EC8E00;
   --tg-orange-2: #c65454;
   --tg-orange10: #EE991A1A;
   --tg-item-gray: #E9EAEA;
   --tg-item-gray50: #e9eaea7b;
   --tg-item-dark: #191E26;
   --tg-solid-gray: #191E26CC;
   --tg-button-effect: rgb(20, 180, 57);
   --tg-gray-line: #BEBFC1;

   --tg-park: #166DF7;
   --tg-charge: #18C941;
   --tg-park-charge: #8716F7;
   --tg-transparent: transparent;

   --tg-black: #000000;
   --tg-dark: #191E26;
   --tg-black-2: rgb(22, 22, 22);
   --tg-black-active: #292F38;

   --tg-white: #FFFFFF;
   --tg-white-2: #EFFFF3;
   --tg-white15: rgba(255, 255, 255, .15);
   --tg-light-green:#CFFF45;
   --tg-soft-green:#E5FAEC;
   --tg-dust-green:#18C9411A;
   --tg-dust-green-2:#18C9410D;

   --tg-receipt-card: rgba(22, 109, 247, .2);

   /* Typography */
   --tg-font-family: 'Montserrat', sans-serif;

}
