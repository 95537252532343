.DefaultPage h1 {
    font-size: 24px;
    font-weight: 600;
}

.DefaultPage h3{
    font-size: 18px;
    font-weight: 600;
}

.DefaultPage h5{
    font-size: 16px;
    font-weight: 600;
}

.DefaultPage p{
    font-size: 14px;
    font-weight: 400;
}

.DefaultPage ul{
    padding-left: 16px;
}
.DefaultPage li{
    font-size: 14px;
    font-weight: 400;
}

.no-top-margin {
    margin-top: 0;
}

.DefaultPage::part(handle) {
    margin: 8px auto;
}