@keyframes OnboardingSecondSlide_Car-animation {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}
@keyframes OnboardingSlideAnimatedCircle-rotate {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes OnboardingSecondSlide_Charge-animation {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes OnboardingSlideAnimatedCircle_2-animation {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    70% {
        transform: scale(1);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes OnboardingSlideAnimatedCircle3-rotate {
    from {
        transform: rotate(0) scale(1.1);
    }
    to {
        transform: rotate(360deg) scale(1.1);
    }
}
@keyframes OnboardingSlideAnimatedCircle3-opacity {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes OnboardingTogerCardAnim {
    0% {
        opacity: 0;
        transform: scale(.2) translateY(300px);
    }
    40% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}
@keyframes OnboardingLastSlidePhoneAnimation {
    0% {
        opacity: 0;
        transform: scale(.2) translateY(500px);
    }
    100% {
        transform: scale(1) translateY(0);
        opacity: 1;
    }
}
@keyframes OnboardingLastSlideRoadAnimation {
    0% {
        opacity: 0;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes OnboardingLastSlideCarAnimation {
    0% {
        transform: translateY(-122px) translateX(36px) scale(0);
        opacity: 0;
    }
    59.9%{
        opacity: 0;
    }
    60% {
        opacity: 1;
        transform: translateY(-127px) translateX(61px) scale(0.2);
    }
    100% {
        transform: translateY(-127px) translateX(36px) scale(0.3);
        opacity: 1;
    }
}
@keyframes OnboardingSlideAnimatedCircle_4-animation {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    60% {
        transform: scale(1);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes OnboardingSecondSlideCarLightsAnimation {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 0;
    }
    90% {
        opacity: 0.8;
    }
    95% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes OnboardingDiscountAnimation {
    0% {
        opacity: 0;
        transform: scale(0.2) translateY(500px);
    }
    50% {
        opacity: 0;
        transform: scale(0.2) translateY(500px);
    }
    70% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}

@keyframes OnboardingSlideChargeBoxAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes OnboardingDiscountLineAnimation_1 {
    0% {
        transform: translateX(15px) translateY(150px) rotate(150deg) scale(.5);;
    }
    100% {
        transform: translateX(0px) translateY(-50px) rotate(150deg) scale(.5);
    }
}
@keyframes OnboardingDiscountLineAnimation_2 {
    0% {
        transform: translateY(120px) translateX(64px) rotate(150deg) scale(.8);
    }
    100% {
        transform: translateX(30px) translateY(-80px) rotate(150deg) scale(.8);
    }
}
@keyframes OnboardingDiscountLineAnimation_3 {
    0% {
        transform: translateX(140px) translateY(90px) scale(.6);
    }
    100% {
        transform: translateX(180px) translateY(-20px) scale(.6);
    }
}

@keyframes OnboardingDiscountBalloonAnimation_1 {
    from {
        transform: translateX(110px) translateY(160px) scale(.2);
    }
    to {
        transform: translateX(150px) translateY(-50px) scale(.6);
    }
}
@keyframes OnboardingDiscountBalloonAnimation_2 {
    from {
        transform: translateX(40px) translateY(200px) scale(.4);
    }
    to {
        transform: translateX(20px) translateY(-50px) scale(.6);
    }
}
@keyframes OnboardingDiscountBalloonAnimation_3 {
    from {
        transform: translateX(100px) translateY(160px) scale(.2);
    }
    to {
        transform: translateX(100px) scale(.6) translateY(-50px);
    }
}

@keyframes OnboardingDiscountOpacityBalloonAnimation_1 {
    from {
        transform: translateX(110px) translateY(120px) scale(.2);
    }
    to {
        transform: translateX(170px) translateY(-50px) scale(.6);
    }
}
@keyframes OnboardingDiscountOpacityBalloonAnimation_2 {
    from {
        transform: translateX(40px) translateY(120px) scale(.4);
    }
    to {
        transform: translateX(20px) translateY(-50px) scale(.6);
    }
}
@keyframes OnboardingDiscountOpacityBalloonAnimation_3 {
    from {
        transform: translateX(80px) translateY(120px) scale(.2);
    }
    to {
        transform: translateX(80px) translateY(-50px) scale(.6);
    }
}

@keyframes OnboardingDiscountIconWrapperAnimation{
    0% {
        opacity: 0;
        transform: translateY(200px) translateX(-25px) scale(.2);
    }
    100%{
        opacity: 1;
        transform: translateY(0px) translateX(-25px) scale(1);
    }
}
@keyframes OnboardingDiscountConfettiAnimation{
    0% {
        transform: translateY(200px) translateX(-25px) scale(.2);
    }
    100%{
        transform: translateY(0px) translateX(0px) scale(1);
    }
}

@keyframes OnboardingDiscountIconAnimation_1 {
    0% {
        transform: translateX(170px) scale(.8);
    }
    50% {
        transform: translateX(175px) scale(1.2);
    }
    100% {
        transform: translateX(170px) scale(.8);
    }
 }
 
@keyframes OnboardingDiscountIconAnimation_2 {
    0% {
        transform: translateX(80px) translateY(-40px) scale(.8);
    }
    50% {
        transform: translateX(85px) translateY(-40px) scale(1.2);
    }
    100% {
        transform: translateX(80px) translateY(-40px) scale(.8);
    }
}
@keyframes OnboardingDiscountIconAnimation_3 {
    0% {
        transform: translateX(20px) translateY(40px) scale(.6);
    }
    50% {
        transform: translateX(25px) translateY(40px) scale(.8);
    }
    100% {
        transform: translateX(20px) translateY(40px) scale(.6);
    }
}
@keyframes OnboardingDiscountIconAnimation_4 {
    0% {
        transform: translateX(90px) translateY(50px) scale(.6);
    }
    50% {
        transform: translateX(95px) translateY(50px) scale(.8);
    }
    100% {
        transform: translateX(90px) translateY(50px) scale(.6);
    }
}
@keyframes OnboardingDiscountIconAnimation_5 {
    0% {
        transform: translateY(100px) translateX(40px) scale(.3);
    }
    50% {
        transform: translateY(100px) translateX(45px) scale(.5);
    }
    100% {
        transform: translateY(100px) translateX(40px) scale(.3);
    }
}
@keyframes OnboardingDiscountIconAnimation_6 {
    0% {
        transform: translateY(100px) translateX(120px) scale(.3);
    }
    50% {
        transform: translateY(100px) translateX(125px) scale(.5);
    }
    100% {
        transform: translateY(100px) translateX(120px) scale(.3);
    }
}
@keyframes OnboardingDiscountIconAnimation_7 {
    0% {
        transform: translateY(140px) translateX(80px) scale(.3);
    }
    50% {
        transform: translateY(140px) translateX(85px) scale(.5);
    }
    100% {
        transform: translateY(140px) translateX(80px) scale(.3);
    }
}


@keyframes receiptContentAnimation {
    0% {
        height: 0;
    }

    50% {
        height: 0;
    }

    100% {
        height: 90%;
    }
}

@keyframes initAnim {
    0% {
        opacity: 0;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@keyframes fill {
    0% {
      rotate: 10deg;
    }
  
    50% {
      rotate: -10deg;
    }
  
    100% {
      rotate: 10deg;
    }
  }
  
  @keyframes fill-reverse {
    0% {
      rotate: -10deg;
    }
  
    50% {
      rotate: 10deg;
    }
  
    100% {
      rotate: -10deg;
    }
  }
  
  @keyframes initializingCarAnimation {
    0% {
      opacity: 0;
      top: 100%;
    }
  
    22% {
      opacity: 0;
      top: 100%;
    }
  
    90% {
      top: 75px;
      opacity: .7;
    }
  
    100% {
      top: 80px;
    }
  }
  
  @keyframes car-animation {
    from {
      transform: translateY(100vh);
    }
  
    to {
      transform: translateY(0);
    }
  }
  @keyframes car-animation-parking {
    from {
      transform: translateY(100vh);
    }
  
    to {
      transform: translateY(-70px);
    }
  }
  
  @keyframes initiailizingLabel {
    0% {
      opacity: 0;
    }
  
    90% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  @keyframes initiailizingParkingArea {
    0% {
      opacity: 0;
    }
  
    90% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes initiailizingTopLabels {
    0% {
      transform: translateY(-200px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes initiailizingBottomLabels {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }

  @keyframes StationDetailElements {
    0% {
        transform: translateY(30px);
        opacity: 0;
        border: none;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
        border: unset;
    }
  }

@keyframes sucessIconAnimation {
    0% {
        transform: translateY(200px);
    }

    100% {
        transform: translateY(15%);
    }
}
@media only screen and (min-height: 750px) {

    
    @keyframes receiptContentAnimation {
        0% {
            height: 0;
        }

        50% {
            height: 0;
        }

        100% {
            height: 70%;
        }
    }

    @keyframes sucessIconAnimation {
        0% {
            transform: translateY(200px);
        }

        100% {
            transform: translateY(30%);
        }
    }

    @keyframes car-animation-parking {
        from {
          transform: translateY(100vh);
        }

        to {
          transform: translateY(-100px);
        }
      }

  @keyframes initiailizingBottomLabels {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}