.Onboarding .swiper .swiper-wrapper{
    height: 600px;
}

.Onboarding .swiper .swiper-pagination{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
}
.Onboarding .swiper-pagination .swiper-pagination-bullet{
    height: 13px;
    width: 13px;
    border-radius: 13px;
    background-color: var(--tg-white);
    border: 0.5px solid var(--tg-secondary-text50);
    display: inline-block;
    transition: all 0.3s ease-in-out;
}
.Onboarding .swiper-pagination .swiper-pagination-bullet-active{
    background-color: var(--tg-primary-text);
}

@media only screen and (max-height: 670px) {
    .Onboarding .swiper .swiper-wrapper{
        height: 540px;
    }
}